<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                    <div class="flex items-center">
                        <div v-if="!showEditButton">
                            <RefreshButton :card-title="title" :annual-report-id="annualReportId" :refresh-data="refreshData" loading-key="loading-businessInfo" />
                        </div>
                        <InfoButton :content="infoContent" />
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div v-if="toggleValue" v-loading="$waiting.is('loading-businessInfo')">
                    <el-input type="textarea" :disabled="inputDisabled" v-model="localInputText" rows="6" class="break-normal custom-textarea" />
                </div>
            </el-form>
            <CardEditButtons :toggle-value="toggleValue" :show-edit-button="showEditButton" :toggle-edit="toggleEdit" :close-modal="closeModal" :create="create" />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";
import Api from "../annualReport.api";
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        inputText: {
            type: String,
            default: "",
        },
        tooltipInfo: {
            type: String,
            default: "",
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        annualReportId: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            selectedYear: null,
            financialYearId: null,
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            infoContent: `Förvaltningsberättelsen ska innehålla en beskrivning om bolagets säte, vilken verksamhet bolaget bedriver, om bolaget bytt firma och andra förhållanden som särskilt berör bolaget. Även det förhållandet att bolaget är privatbostadsföretag enligt inkomstskattelagen ska framgå. <br /><br /> K2 (BFNAR 2016:10) kapitel 4 punkt 9 och kapitel 5 punkt 2, 3, 6`,
            modalVisible: false,
            localInputText: this.inputText,
            initialInputText: "",
            isInitialInputTextSet: false,
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("./components/CardEditButtons.vue"),
        InfoButton: () => import("./components/InfoButton.vue"),
        RefreshButton: () => import("./components/RefreshData.vue"),
    },
    watch: {
        inputText(newVal) {
            this.localInputText = newVal;
            if (!this.isInitialInputTextSet) {
                this.initialInputText = newVal;
                this.isInitialInputTextSet = true;
            }
        },
        localInputText(newVal) {
            this.$emit("update:text", newVal);
        },
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
    },
    methods: {
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 2,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async create() {
            this.$emit("create");
            this.$notify.success({ title: 'Sparat "Allmänt om verksamheten"' });
            this.toggleEdit();
        },
        closeModal() {
            const load = "loading-businessInfo";
            this.$emit("close", { load: load, inputTextNewValue: this.inputTextNewValue });
            console.log("closeModal", load);
            this.toggleEdit();
        },
        async refreshData() {
            const text = await Api.getManagementReportSyna(this.annualReportId, "generalBusinessInfo");
            this.$emit("update:text", text);
        },
    },
    computed: {
        inputTextNewValue() {
            return this.initialInputText != this.localInputText;
        },
    },
};
</script>
